<template>
    <v-dialog v-model="dialog_page_settings" width="640" content-class="card   settings-dialog "
    >

        <v-btn
            icon
            dark
            variant="text"
            class="dialog-close-btn"
            @click="$emit('close-dialog')"
          >          
            <v-icon>mdi-close</v-icon>
          </v-btn>
      <div class="overflow-y-auto h-100 settings-dialog-card">
<v-row>
<v-col cols="12" md="6">
    <p >Afmeldtermijn in uren</p>
    <k-spacing y="2"></k-spacing>

    <v-text-field type="number" v-model="editsettings.cancellation_period"  v-bind="props" variant="solo" ></v-text-field>

</v-col>
</v-row>    
<v-row>
    <v-col cols="12" md="7">
        <p >Aantal credits per medewerker</p>
    <k-spacing y="2"></k-spacing>

        <v-text-field type="number" v-model="editsettings.credits" v-bind="props" variant="solo" ></v-text-field>

    </v-col>
    <v-col cols="12" md="5">
        <p >Periode</p>
    <k-spacing y="2"></k-spacing>

        <v-select v-model="editsettings.period" :items="periods" item-title="title" item-value="value" v-bind="props" variant="solo" ></v-select>

    </v-col>
</v-row>  

<v-row>
    <v-col cols="12" md="7">
        <p >Duratie inactiviteit mails</p>
    <k-spacing y="2"></k-spacing>

        <v-text-field type="number" v-model="editsettings.inactive_amount" v-bind="props" variant="solo" ></v-text-field>

    </v-col>
    <v-col cols="12" md="5">
        <p >Periode inactiviteit mails</p>
    <k-spacing y="2"></k-spacing>

        <v-select v-model="editsettings.inactive_period" :items="mail_periods" item-title="title" item-value="value" v-bind="props" variant="solo" ></v-select>

    </v-col>
</v-row>  
<v-row>

    <v-col cols="12" md="5">
        <p >Openbare pagina </p>
        <v-switch
          v-model="editsettings.public_page"
          inset
          color="var(--primary)"
        ></v-switch>
    </v-col>
    </v-row>
    <v-row>
    
    <v-col cols="12" md="5">
        <p >Collega's uitnodigen </p>
        <v-switch
          v-model="editsettings.invite_colleagues"
          inset
          color="var(--primary)"
        ></v-switch>
    </v-col>
    </v-row>
<v-row>
    
<v-col cols="12" md="5">
    <p >Herinneringsmail 1</p>
    <v-switch
      v-model="editsettings.notice_mail_1"
      inset
      color="var(--primary)"
    ></v-switch>
</v-col>
</v-row>  
<v-row>
<v-col cols="12" md="5">
    <p >Herinneringsmail 2</p>
    <v-switch
      v-model="editsettings.notice_mail_2"
      inset
      color="var(--primary)"
    ></v-switch>
</v-col>
</v-row>  
<v-row>
<v-col cols="12" md="5">
    <p >Laatste plekken e-mail</p>

    <v-switch
      v-model="editsettings.last_spots_mail"
      inset
      color="var(--primary)"
    ></v-switch>
</v-col>
</v-row>  
<!-- <v-row>
<v-col cols="12" md="5">
    <p >Reviews e-mail</p>
    <v-switch
      v-model="editsettings.review_mail"
      inset
      color="var(--primary)"
    ></v-switch>
</v-col>
</v-row>     -->
       
    </div>
    <k-spacing y="6"></k-spacing>
        <v-row cols="12" md="5">
            <v-btn @click="$emit('update-settings', this.editsettings)" class="btn text-white" color="var(--tertiary)">
                                        Opslaan
                                    </v-btn>
        </v-row>
    </v-dialog>
</template>

<script>

export default {
    props: {
        settings:Object
    },
mounted(){
this.editsettings = this.settings != undefined ? this.getOwnPropertyDescriptors(this.settings) : this.editsettings;
},

    data() {
        return {
            dialog_page_settings: false,
            copysnackbar: "",
            editsettings: {
            },
            periods:[
                {
                    title: 'Dag',
                    value: 'day'
                },
                {
                    title: 'Week',
                    value: 'week'
                },
                {
                    title: 'Maand',
                    value: 'month'
                },
                {
                    title: 'Jaar',
                    value: 'year'
                },
            ],
            mail_periods:[
                {
                    title: 'Dag',
                    value: 'day'
                },
                {
                    title: 'Week',
                    value: 'week'
                },
                {
                    title: 'Maand',
                    value: 'month'
                },
               
            ],
            copysnackbar_status: "",
        }
    },
    methods: {
        getOwnPropertyDescriptors(model) {
      var obj = JSON.parse(JSON.stringify(model));


      Object.keys(obj).reduce(function (obj, key) {
        if (obj["_" + key] === obj[key]) {
          delete obj["_" + key];
        }
        return obj;
      }, obj);
      return obj;
    },
        updateLink(newLink) {
            // Emit an input event to update the prop value
            this.$emit('update:link', newLink);
        },

   

       
        
    },
};


</script>